<template>
  <section class="flex-row-centered steps-component wl-steps">
    <div class="step-space"
      role="presentation">
      <el-button v-if="selectedStep > 0"
        type="primary"
        :plain="!canPreviousStep"
        :disabled="!canPreviousStep"
        data-name="back-button"
        @click="gotoStep(selectedStep - 1)">
        <i class="fas fa-arrow-circle-left"
          aria-hidden="true" /> {{ $t('general.back') }}
      </el-button>
    </div>
    <div class="steps-holder">
      <el-steps :active="selectedStep"
        align-center
        class="steps"
        :class="{ 'success-color': displayCompleteAsSuccess }">
        <el-step v-for="(step, index) in steps"
          :key="index"
          :title="step.title"
          :class="{ 'cursor-pointer': step.status !== 'wait' }"
          :status="displayStatusForStep(index)"
          :icon="step.icon"
          :data-step-name="step.title"
          @click.native="gotoStep(index)" />
      </el-steps>
    </div>
    <div class="step-space"
      role="presentation">
      <el-button v-if="selectedStep < steps.length - 1"
        type="primary"
        :plain="!canNextStep"
        data-name="next-button"
        @click="gotoStep(selectedStep + 1)">
        {{ $t('general.next') }}
        <i class="fas fa-arrow-circle-right"
          aria-hidden="true" />
      </el-button>
    </div>
  </section>
</template>

<script>
// NOTE: This component is only used for the MVE and move connection pages
// Every other page has its own custom steps that do the exact same thing 🤬
import {
  PROCESS_STEP_UNAVAILABLE,
  PROCESS_STEP_AVAILABLE,
  PROCESS_STEP_COMPLETE,
} from '@/Globals.js'

export default {
  name: 'ProcessSteps',

  props: {
    /**
     * An array of the steps to be shown. Each one must have the following fields:
     *
     * - title: What is displayed for that step
     * - status: The status of the step - must be one of the following:
     *   - unavailable
     *   - available
     *   - complete
     *
     * There is also an optional "icon" field to show a specific icon on the step
     */
    steps: {
      type: Array,
      required: true,
      validator: value => {
        if (!Array.isArray(value)) {
          return false
        }
        for (const item of value) {
          if (!Object.prototype.hasOwnProperty.call(item, 'title') ||
            !Object.prototype.hasOwnProperty.call(item, 'status')) {
            return false
          }
          if (![PROCESS_STEP_UNAVAILABLE, PROCESS_STEP_AVAILABLE, PROCESS_STEP_COMPLETE].includes(item.status)) {
            return false
          }
        }
        return true
      },
    },
    /**
     * The index of the step that is selected. This prop will be emitted with an update
     * so it can be used in a .sync if so desired. Valid values are between 0 and
     * steps.length-1
     */
    selectedStep: {
      type: Number,
      default: 0,
    },
    /**
     * Whether to display the completed steps as checkmarks and in green.
     * Icons will still be shown, but will also be in green (success colour)
     */
    displayCompleteAsSuccess: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to show a message when you try to go to another step and the
     * current one isn't complete
     */
    showIncompleteWarning: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['validateStep', 'update:selectedStep'],

  computed: {
    canPreviousStep() {
      return this.steps[this.selectedStep - 1].status !== PROCESS_STEP_UNAVAILABLE
    },
    canNextStep() {
      return this.steps[this.selectedStep].status === PROCESS_STEP_COMPLETE
    },
  },

  methods: {
    /**
     *
     * - wait: You can't go to this step yet
     * - finish: The step is done and will display the step number
     * - success: The step is done and will display a checkmark
     * - process: The step is available to be edited
     */
    displayStatusForStep(index) {
      switch (this.steps[index].status) {
        case PROCESS_STEP_AVAILABLE:
          return this.selectedStep === index ? 'process' : 'finish'
        case PROCESS_STEP_UNAVAILABLE:
          return 'wait'
        case PROCESS_STEP_COMPLETE:
          if (this.selectedStep === index) {
            return 'process'
          }
          if (this.steps[index].icon) {
            return 'finish'
          }
          return this.displayCompleteAsSuccess ? 'success' : 'finish'
        default:
          break
      }
    },
    gotoStep(index) {
      const nextButtons = document.querySelectorAll('button[data-name="next-button"]')
      for (const button of nextButtons) {
        button.blur()
      }
      // If the current step is incomplete, then trigger an update of its visual validation
      // status. If the step we are trying to go to is before the current step, then we
      // bypass the validation of the step since we're going back.
      if (this.steps[this.selectedStep].status === PROCESS_STEP_AVAILABLE && index >= this.selectedStep) {
        this.$emit('validateStep', this.selectedStep)
      }

      // Only go to the requested step if it's available.
      if (this.steps[index].status === PROCESS_STEP_UNAVAILABLE) {
        if (this.showIncompleteWarning) {
          this.$alert(this.$t('general.step-unavailable'), this.$t('general.not-available'), {
            showClose: false,
            type: 'warning',
          })
        }
        return false
      }
      this.$emit('update:selectedStep', index)
    },
  },
}
</script>

<style lang="scss" scoped>
.steps-component {
  padding: 1rem;
}
.step-space {
  margin: 0.5rem;
  width: 100px;
}
.steps-holder {
  flex: 1;
}
.steps {
  max-width: 500px;
  margin: auto;
  margin-top: 1.3rem;
}

::v-deep {
  .steps-holder {
    .el-step__icon.is-icon {
      background-color: var(--mp-sub-header-background-color);
    }

    .success-color .is-finish {
      .el-step__icon.is-icon {
        color: var(--color-success);
      }
      .el-step__icon.is-text {
        color: var(--color-success);
        border-color: var(--color-success);
      }
      &.el-step__title {
        color: var(--color-success);
      }
    }
  }

  .el-step__title {
    margin-inline: 2px;
  }
}
</style>
