<template>
  <el-form-item :prop="prop"
    :label="$t(`connections.${destinationServiceUid ? 'new-preferred-end-vlan' : 'preferred-end-vlan'}`, { title })"
    :label-width="labelWidth"
    class="vlan-input">
    <div class="flex-row-centered">
      <el-tooltip v-if="canUntag"
        placement="top"
        :content="tooltipContent"
        :open-delay="500">
        <div class="flex-row-centered">
          <p class="m-0 mr-1 white-space-nowrap">
            {{ $t('connections.untag') }}
          </p>
          <el-switch v-model="vlanUntagged"
            class="mr-1"
            :disabled="isDisabled"
            data-name="untagSwitch"
            @change="untaggedChanged" />
        </div>
      </el-tooltip>
      <el-input ref="name"
        type="number"
        data-demo="90"
        :value="vlanValue"
        :placeholder="placeHolder"
        :min="minVlan"
        :max="maxVlan"
        :disabled="isDisabled || (canUntag && vlanIsUntagged)"
        class="vlanInput"
        :class="{ transparent: canUntag && vlanIsUntagged }"
        :name="name"
        data-name="preferredVlan"
        @input="vlanChanged" />
    </div>
    <div class="message-style"
      :class="messageClass"
      :data-status="checkStatus"
      v-html="msg" /> <!-- eslint-disable-line vue/no-v-html -->
  </el-form-item>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import sdk, { CanceledError } from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'

export default {
  name: 'InputVlan',

  props: {
    // The el-form prop that this input relates to
    prop: {
      type: String,
      required: false,
      default: '',
    },
    // So it can match the rest of your form
    labelWidth: {
      type: String,
      required: false,
      default: null,
    },
    // The connection that is being configured
    service: {
      type: Object,
      required: true,
    },
    // The destination service uid that the connection is being configured to
    // This is only ever needed when moving a connection
    destinationServiceUid: {
      type: String,
      required: false,
      default: null,
    },
    // The VLAN (using v-model)
    value: {
      type: Number,
      required: false,
      default: null,
    },
    // The second VLAN (inner) (using v-model)
    // This value is only used when it is required to change both the VLAN and innerVlan
    // in which case the value prop becomes the VLAN and this prop becomes the innerVlan
    secondValue: {
      type: Number,
      required: false,
      default: null,
    },
    // The original VLAN value (used when editing a connection)
    originalVlan: {
      type: Number,
      required: false,
      default: null,
    },
    // The original inner VLAN value (used when editing a connection)
    originalInnerVlan: {
      type: Number,
      required: false,
      default: null,
    },
    // The end of the connection being configuring so we can look at the right port
    end: {
      type: String,
      required: true,
      validator: function(value) {
        return ['a', 'b'].includes(value)
      },
    },
    // Force the control to be disabled (this control will also be disable if part of a disabled el-form)
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Whether user input on this control is required
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      vlan: this.value,
      vlanUntagged: [null, -1].includes(this.value),
      msg: '',
      messageClass: '',
      checkStatus: '',
      isDisabled: false,
      vlanValid: false,
      checkingVlan: false,
      abortController: null,
      minVlan: 2,
      maxVlan: 4093,
      fresh: true,
    }
  },

  computed: {
    ...mapGetters('Services', ['targetPorts', 'findPort']),

    /**
     * Use this for the input so we have finer grained control over what is displayed, rather than just
     * displaying the underlying vlan value. Allows for handling unassigned and untagged vlans.
     */
    vlanValue() {
      let inputDisplayedString

      if (this.isDisabled) {
        if (this.vlanIsUntagged) {
          inputDisplayedString = this.$t('connections.untagged')
        } else if (this.vlanIsUserSelected) {
          inputDisplayedString = this.vlan
        }
      } else if (this.vlanIsRandomlySelected || this.vlanIsUntagged) {
        inputDisplayedString = ''
      } else {
        inputDisplayedString = this.vlan
      }

      return inputDisplayedString
    },
    placeHolder() {
      if (this.vlanIsUntagged) return this.$t('connections.untagged')

      if (this.required) return ''

      return this.$t('connections.auto-assign')
    },
    /**
     * Returns the service end (A or B) substring to be used in determining the input's label
     */
    title() {
      // Not relevant for IX connections
      if (this.connectionIsIx || this.service.bEnd?.ixType) return null

      return this.$t('general.x-end', { end: this.end.toUpperCase() })
    },
    /**
     * Gives us a common method to get the A or B end of the service.
     */
    serviceEnd() {
      // If the connection being configured is an IX, we can only check the A-End (parent port).
      if (this.connectionIsIx) {
        return this.findIxParentPort(this.service)
      }

      const serviceEnd = `${this.end.toLowerCase()}End`

      return this.service[serviceEnd]
    },
    /**
     * Returns the port object for the service end
     */
    port() {
      return this.targetPorts.find(port => port.productUid === this.serviceEnd?.productUid) || {}
    },
    /**
     * For a MEGAPORT, a VXC can only be untagged if it is the only connection on the port.
     * For an MVE, there can be at most one untagged VXC per vNIC.
     */
    canUntag() {
      if (this.aEndIsMve && this.end === 'a') {
        if (this.aEndVNicUntaggedVxc) {
          const editingUntaggedVxc = this.aEndVNicUntaggedVxc.productUid === this.service.productUid

          return editingUntaggedVxc
        } else {
          return true
        }
      } else if (this.bEndIsMve && this.end === 'b') {
        if (this.bEndVNicUntaggedVxc) {
          const editingUntaggedVxc = this.bEndVNicUntaggedVxc.productUid === this.service.productUid

          return editingUntaggedVxc
        } else {
          return true
        }
      } else {
        const serviceHasNoOtherConnection = this.allConnections.length === 0
        const editingOnlyConnection = this.allConnections.length === 1 && this.allConnections[0].productUid === this.service.productUid

        return serviceHasNoOtherConnection || editingOnlyConnection
      }
    },
    vlanIsUntagged() {
      return [null, -1].includes(this.vlan)
    },
    vlanIsRandomlySelected() {
      return this.vlan === 0
    },
    vlanIsUserSelected() {
      return this.vlan > 1
    },
    name() {
      return `${this.end}EndVLAN`
    },
    allConnections() {
      return [...(this.port.associatedIxs || []), ...(this.port.associatedVxcs || [])]
    },
    /**
     * Finds all the VLANs that are in use on the destination port by other in-DESIGN connections.
     * These VLANs may be at the A or B end so we need to look at which end is connected
     * to the port that is at the end of the current connection.
     */
    localUsedVlans() {
      const vlans = []

      // Check all connections that are in design but are not the current one
      const inDesignConnections = this.allConnections.filter(connection => connection.productUid !== this.service.productUid && connection.provisioningStatus === this.G_PROVISIONING_DESIGN)

      for (const connection of inDesignConnections) {
        let endData = {}

        if (connection.productType === this.G_PRODUCT_TYPE_IX) {
          endData = this.findIxParentPort(connection)
        } else {
          endData = connection.aEnd.productUid === this.port.productUid ? connection.aEnd : connection.bEnd
        }

        if (endData.vlan) {
          vlans.push(endData.vlan)
        }

        if (endData.innerVlan) {
          vlans.push(endData.innerVlan)
        }
      }

      return vlans
    },
    // Whether an IX connection is being configured
    connectionIsIx() {
      return this.service.productType === this.G_PRODUCT_TYPE_IX
    },
    aEndService() {
      const aEndServiceUid = this.service.aEnd.productUid

      return this.findPort(aEndServiceUid)
    },
    aEndIsMve() {
      return this.aEndService?.productType === this.G_PRODUCT_TYPE_MVE
    },
    bEndService() {
      const bEndServiceUid = this.service.bEnd.productUid

      return this.findPort(bEndServiceUid)
    },
    bEndIsMve() {
      return this.bEndService?.productType === this.G_PRODUCT_TYPE_MVE
    },
    // The selected vNIC in the A-End chosen for the connection being configured
    aEndVNicIndex() {
      return this.aEndIsMve ? this.service.aEnd.vNicIndex : null
    },
    // The untagged VXC for the selected vNIC on the the A-End
    aEndVNicUntaggedVxc() {
      if (!this.aEndIsMve) return null

      return this.aEndService.associatedVxcs?.find(vxc => {
        if (![vxc.aEnd.productUid, vxc.bEnd.productUid].includes(this.aEndService.productUid)) return false

        if (vxc.aEnd.productUid === this.aEndService.productUid) {
          return vxc.aEnd.vNicIndex === this.aEndVNicIndex && [null, -1].includes(vxc.aEnd.innerVlan)
        }

        if (vxc.bEnd.productUid === this.aEndService.productUid) {
          return vxc.bEnd.vNicIndex === this.aEndVNicIndex && [null, -1].includes(vxc.bEnd.innerVlan)
        }
      }) || null
    },
    // The selected vNIC in the B-End chosen for the connection being configured
    bEndVNicIndex() {
      return this.bEndIsMve ? this.service.bEnd.vNicIndex : null
    },
    // The untagged VXC for the selected vNIC on the the B-End
    bEndVNicUntaggedVxc() {
      if (!this.bEndIsMve) return null

      return this.bEndService.associatedVxcs?.find(vxc => {
        if (![vxc.aEnd.productUid, vxc.bEnd.productUid].includes(this.bEndService.productUid)) return false

        if (vxc.aEnd.productUid === this.bEndService.productUid) {
          return vxc.aEnd.vNicIndex === this.bEndVNicIndex && [null, -1].includes(vxc.aEnd.innerVlan)
        }

        if (vxc.bEnd.productUid === this.bEndService.productUid) {
          return vxc.bEnd.vNicIndex === this.bEndVNicIndex && [null, -1].includes(vxc.bEnd.innerVlan)
        }
      }) || null
    },
    tooltipContent() {
      if ((this.end === 'a' && this.aEndIsMve) || (this.end === 'b' && this.bEndIsMve)) {
        return this.$t('connections.mve-untag-vlan-tooltip')
      } else {
        return this.$t('connections.untag-vlan-tooltip')
      }
    },
    serviceIsInDesign() {
      return this.service.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    isEstablishedIbmConnection() {
      return this.port.provisioningStatus !== this.G_PROVISIONING_DESIGN && this.service.bEnd?.partnerConfig?.connectType === 'IBM'
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.vlan = newVal
        this.vlanUntagged = [null, -1].includes(newVal)
      },
    },
  },

  created() {
    // Create a debounced version with a delay for the user to finish typing.
    this.debouncedCheckVlanAvailability = debounce(this.checkVlanAvailability, 500)
  },

  mounted() {
    this.$nextTick(() => {
      // Considered to be disabled if either explicitly set in the props or if the form is disabled.
      if (!this.$refs.name) {
        this.isDisabled = this.disabled
      } else {
        this.isDisabled = this.disabled || (this.$refs.name.elForm || {}).disabled
      }
    })
  },

  beforeDestroy() {
    this.debouncedCheckVlanAvailability.cancel()
  },

  methods: {
    finishedCheckingWithStatus(valid, status, message) {
      this.vlanValid = valid
      this.checkStatus = status

      switch (status) {
        case 'unavailable':
        case 'error':
        case 'empty':
        case 'untagged':
          this.messageClass = 'color-warning'
          break
        case 'success':
          this.messageClass = 'color-success'
          break
        default:
          captureSentryError(new Error(`Unrecognised status: ${status}`))
          this.messageClass = 'color-warning'
          break
      }

      this.msg = message
      this.checkingVlan = false

      this.$emit('vlanCheckComplete')
    },
    /**
     * Checks on the network to see if the VLAN is available.
     * Called after it has passed all the local tests.
     */
    async checkVlanOnNetwork(value) {
      this.messageClass = 'color-warning'
      this.checkStatus = 'checking'
      this.msg = this.$t('connections.vlan-checking')

      let vlan
      let replacingVlan, innerVlan, replacingInnerVlan = null

      if (this.connectionIsIx) {
        vlan = value

        if (!this.serviceIsInDesign) replacingVlan = this.service.vlan
      } else if (this.port.productType === this.G_PRODUCT_TYPE_MVE) {
        vlan = this.serviceEnd.vlan
        innerVlan = value

        if (!this.serviceIsInDesign) {
          replacingVlan = vlan
          replacingInnerVlan = this.originalInnerVlan
        }

        // Both the VLAN and inner VLAN can change when editing the vNIC of the connection
        // VLAN refers to the vNICs VLAN and inner VLAN refers to the preferred VLAN of the connection
        if (this.secondValue) {
          vlan = this.secondValue
          replacingVlan = this.originalVlan
        }
      } else {
        vlan = value

        if (!this.serviceIsInDesign) replacingVlan = this.serviceEnd.vlan
      }

      // Use the destination service UID if it's available, otherwise fallback to the current service end's product UID
      const productUid = this.destinationServiceUid ?? this.serviceEnd?.productUid

      const vlansSet = new Set()

      /*---------- VLAN Network Validation ----------*/
      try {
        let signal = null

        this.abortController = new AbortController()

        signal = this.abortController.signal

        const vlans = await sdk.instance
          .product(productUid)
          .checkVlan(vlan, replacingVlan, innerVlan, replacingInnerVlan, { signal })

        if (vlans.vlan) {
          vlansSet.add(vlans.vlan)

          vlans.suggested_vlans.forEach(item => vlansSet.add(item))
        } else {
          vlans.forEach(item => vlansSet.add(item))
        }
      } catch (error) {
        // If it was aborted by a new request coming in, don't treat it as an error.
        // The new request will handle the status updates.
        if (error instanceof CanceledError) {
          // Something unexpected went wrong with the fetch
          captureSentryError(error)

          this.finishedCheckingWithStatus(false, 'error', this.$t('validations.vlan-unavailable'))
        }

        return false
      }

      // The checkVlan method will either return an array with one item in it, which will be the requested
      // VLAN, indicating that it's OK, or it will be a list of suggested VLANs.
      if (vlansSet.size > 1) {

        const message = this.$t(`VLAN unavailable.<br/>${[...vlansSet].join(', ')} are known to be available for use on this service.`)

        this.finishedCheckingWithStatus(false, 'unavailable', message)
      } else {
        this.finishedCheckingWithStatus(true, 'success', this.$t('validations.vlan-available'))
      }
    },
    /**
     * Perform initial checks to make sure that the VLAN is OK to use. If it passes these checks, and the
     * port is deployed on the network, we call to the network to check the availability of the VLAN.
     */
    checkVlanAvailability(vlan) {
      // Since the VLAN is not editable for not-in-DESIGN IBM connections,
      // skip the check by marking it as successful check and displaying no UI message.
      if (this.isEstablishedIbmConnection) {
        this.finishedCheckingWithStatus(true, 'empty', '')

        return true
      }

      this.fresh = false

      let message

      if (!vlan) {
        if (this.required) {
          this.finishedCheckingWithStatus(false, 'error', this.$t('validations.vlan-required'))

          return false
        } else {
          this.finishedCheckingWithStatus(true, 'empty', this.$t('validations.vlan-auto-assign'))

          return true
        }
      }

      if (vlan === -1) {
        if (!this.canUntag) {
          this.finishedCheckingWithStatus(false, 'error', this.$t('validations.vlan-no-untagged'))

          return false
        } else {
          if ((this.end === 'a' && this.aEndIsMve) || (this.end === 'b' && this.bEndIsMve)) {
            message = ''
          } else {
            message = this.$t('validations.vlan-untagged-warning')
          }

          this.finishedCheckingWithStatus(true, 'untagged', message)

          return false
        }
      }

      if (vlan < this.minVlan || vlan > this.maxVlan) {
        message = this.$t(`validations.vlan-range`, { minVlan: this.minVlan, maxVlan: this.maxVlan })

        this.finishedCheckingWithStatus(false, 'error', message)

        return false
      }

      if (this.localUsedVlans.includes(vlan)) {
        this.finishedCheckingWithStatus(false, 'error', this.$t('validations.vlan-in-use'))

        return false
      }

      let needsNetwork = false

      if (this.port.provisioningStatus !== this.G_PROVISIONING_DESIGN && this.serviceEnd) {
        needsNetwork = true
      }

      if (needsNetwork) {
        this.checkVlanOnNetwork(vlan)
      } else {
        this.finishedCheckingWithStatus(true, 'success', this.$t('validations.vlan-available'))
      }
    },
    /**
     * Method to call from the holder component to trigger the validation to
     * run and the UI to update accordingly.
     */
    triggerValidation() {
      this.vlanChanged(this.vlan, true)
    },
    vlanChanged(vlan, immediate = false) {
      const vlanParam = vlan === null ? -1 : vlan || 0

      // Abort if there's already an online check in place
      this.abortController?.abort()
      // Don't know if it's valid yet
      this.vlanValid = false
      // Any watchers need to know that it's awaiting checking
      this.checkingVlan = true
      // Don't display anything in the initial debounce period
      this.msg = ''
      // Save the value locally
      this.vlan = parseInt(vlanParam)

      if (immediate) {
        this.checkVlanAvailability(this.vlan)
      } else {
        // Queue up a check
        this.debouncedCheckVlanAvailability(this.vlan)
      }

      // Emit the updated value
      this.$emit('input', this.vlan || 0)
    },
    untaggedChanged() {
      this.vlanUntagged ? this.vlanChanged(-1) : this.vlanChanged(0)
    },
    findIxParentPort(ixData) {
      const portUid = Array.isArray(ixData.parentPortUid) ? ixData.parentPortUid[0] : ixData.parentPortUid

      return this.targetPorts.find(port => port.productUid === portUid) || false
    },
  },
}
</script>

<style lang="scss" scoped>
.message-style {
  line-height: 1.8rem;
  margin-top: 0.5rem;
  // Ensure the message does not expand input
  max-width: fit-content;
  @-moz-document url-prefix() {
    max-width: 36rem !important; // Have to be explicit for firefox
  }
}
.vlanInput {
  min-width: 160px;
}
</style>

<style lang="scss">
.vlan-input .el-form-item__error {
  display: none;
}
</style>
