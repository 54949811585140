import store from '@/store/Store'
import {
  G_PROVISIONING_DESIGN,
  G_PROVISIONING_LOADING,
  G_PROVISIONING_DEPLOYABLE,
  G_PROVISIONING_NEW,
  G_PRODUCT_TYPE_MVE,
  G_PRODUCT_TYPE_MCR2,
} from '@/Globals.js'

/**
 * Standard function for returning a translation key for a reason why a given connection cannot be moved, or null if it
 * can be moved. Based off the nonEditReason computed property in EditConnection.vue
 * @param {object} connection the connection to be checked
 * @returns {string | null} A translation key of the reason why the connection can't be moved, or null if it can
 */
export const connectionNoMoveReason = connection => {
  // Directly referencing the store has a chance to make our lives hell in the unit tests
  // However, none of the tests broke when I did it so 🤷
  if (!store.getters['Auth/hasAuth']('modify_service')) {
    return 'services.move-permission-denied'
  } else if ([G_PROVISIONING_DESIGN, G_PROVISIONING_LOADING, G_PROVISIONING_DEPLOYABLE].includes(connection.provisioningStatus)) {
    return 'services.type-move-notlive'
  } else if (connection.adminLocked || connection.locked) {
    return 'services.type-locked'
  } else if (connection.vxcApproval?.type === G_PROVISIONING_NEW) {
    return 'services.awaiting-approval'
  }
  return null
}

/**
 * Standard function for returning a translation key for a reason why a given port-like's connections cannot be moved,
 * or null if they can be moved.
 * @param {object} portlike the port-like object to be checked
 * @returns {string | null} A translation key of the reason why connections can't be moved, or null if they can
 */
export const portlikeNoMoveReason = portlike => {
  // Check that there are actually valid destinations for the connections
  if (store.getters['Services/getValidMoveTargets'](portlike).length < 1) {
    switch (portlike.productType) {
      case G_PRODUCT_TYPE_MCR2: return 'services.cant-move-no-mcrs'
      case G_PRODUCT_TYPE_MVE: return 'services.cant-move-no-mves'
      default: return 'services.cant-move-no-ports'
    }
  }
  return null
}
