<template>
  <div class="move-connection-cell"
    data-testid="port-cell"
    :data-service-name="destination.productName"
    :data-location="destinationLocation"
    :data-diversity-zone="destination.diversityZone || 'none'">
    <!-- Destination Icon -->
    <div class="move-connection-icon">
      <mu-mega-icon :icon="destination.productType" />
    </div>
    <!-- Destination Details -->
    <div class="move-connection-details">
      <strong class="move-connection-line">{{ destination.productName }}</strong>
      <span class="move-connection-line">{{ destinationDetails }}</span>
      <span class="move-connection-line">{{ destinationLocation }}</span>
      <span v-if="destination.diversityZone"
        class="diversity-zone">
        {{ $t('services.diversity-zone') }}
        <i class="far fa-dot-circle"
          :class="`diversity-color-${destination.diversityZone}`"
          aria-hidden="true" />
      </span>
    </div>
  </div>
</template>

<script>
import { formatMveVendorName } from '@/helpers.js'

export default {
  name: 'DestinationDetails',

  props: {
    destination: {
      type: Object,
      required: true,
    },
  },

  computed: {
    destinationDetails() {
      if (this.destination.productType === this.G_PRODUCT_TYPE_MVE) {
        const mveVendor = this.destination.vendor
          ? formatMveVendorName(this.destination.vendor)
          : this.destination.vendorConfig?._vendor
        return `${this.$t('ports.vendor')}: ${mveVendor}, ${this.$t('general.size')}: ${this.destination.mveLabel}`

      } else {
        return this.destination._speed
      }
    },
    destinationLocation() {
      return this.destination._location?.formatted?.short ?? this.$t('general.unknown-location')
    },
  },
}
</script>
