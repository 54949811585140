<template>
  <el-row type="flex"
    justify="center"
    align="middle"
    class="w-1000px">
    <el-col class="w-760px">
      <div class="bs-border-box">
        <el-card shadow="never">
          <!-- Sub-title: Summary -->
          <h3 class="my-1 text-align-center font-weight-500">
            {{ $t('general.summary') }}
          </h3>

          <!-- Summary Table -->
          <el-table :data="movedConnections"
            border
            class="summary-table"
            cell-class-name="summary-table-cell">
            <!-- Column: Connection -->
            <el-table-column :label="$t('general.connection')"
              width="400">
              <template slot-scope="{row}">
                <connection-details :connection="row"
                  :parent-uid="currentService.productUid" />
              </template>
            </el-table-column>

            <!-- Column: vNIC Details -->
            <el-table-column v-if="isMovingMultipleVNics"
              :label="$t('connections.vnic-details')"
              width="180">
              <template slot-scope="{row}">
                <strong class="-mb-1rem">
                  {{ $t('connections.end-vnic', { end: end(row) }) }}
                </strong>
                <span>
                  {{ selectedVNic(row.productUid).vNicDescription }}
                </span>
                <strong class="-mb-1rem">
                  {{ $t('connections.preferred-end-vlan', { title: $t('general.x-end', { end: end(row) }) }) }}
                </strong>
                <span>
                  {{ selectedVNic(row.productUid).innerVlan }}
                </span>
              </template>
            </el-table-column>

            <!-- Column: Status -->
            <el-table-column :label="$t('general.status')"
              prop="status">
              <template slot-scope="{row}">
                <span v-if="row.status === STATUS_PROCESSING">
                  {{ $t('general.processing') }}
                  <i class="far fa-hourglass fa-beat ml-0-5"
                    aria-hidden="true" />
                </span>
                <template v-else-if="row.status === STATUS_FAILED">
                  <strong>
                    {{ $t('general.failure') }}
                    <i class="far fa-circle-xmark color-danger ml-0-5"
                      aria-hidden="true" />
                  </strong>
                  <span>
                    {{ row.error }}
                  </span>
                </template>
                <strong v-else-if="row.status === STATUS_SUCCEEDED">
                  {{ $t('general.success') }}
                  <i class="far fa-check-circle color-success ml-0-5"
                    aria-hidden="true" />
                </strong>
                <span v-else>
                  {{ $t('general.not-submitted') }}
                </span>
              </template>
            </el-table-column>
          </el-table>

          <!-- Move Delay Disclaimer -->
          <el-alert type="info"
            :title="$t('general.modification-delay')"
            :closable="false"
            center
            show-icon
            class="my-1 py-1" />

          <!-- Move Button -->
          <el-button type="primary"
            class="d-block ml-auto"
            :plain="submitted"
            :disabled="submitted"
            @click="showConfirmationDialog = true">
            {{ $t('general.move') }}
            <i class="fas fa-check-circle"
              aria-hidden="true" />
          </el-button>

          <!-- Confirmation Dialog -->
          <simple-confirmation-dialog :visible="showConfirmationDialog"
            :title="$t('connections.confirm-move')"
            class="confirmation-dialog"
            @cancel="showConfirmationDialog = false"
            @confirm="submitMove">
            <i18n path="connections.move-connection-confirm"
              tag="p">
              <template #currentEnd>
                <strong>{{ currentService.productName }}</strong>
              </template>
              <template #newEnd>
                <strong>{{ destinationService.productName }}</strong>
              </template>
            </i18n>
            <i18n v-if="showDiversityWarning"
              path="connections.move-connection-diversity"
              tag="p">
              <template #currentDiv>
                <span :class="`diversity-color-${currentService.diversityZone}`">{{ $t(`services.${currentService.diversityZone}-zone`) }}</span>
              </template>
              <template #newDiv>
                <span :class="`diversity-color-${destinationService.diversityZone}`">{{ $t(`services.${destinationService.diversityZone}-zone`) }}</span>
              </template>
            </i18n>
            <p v-if="isMovingMultipleVNics">
              {{ $t('connections.move-connection-vnic-warning') }}
            </p>
            <p>{{ $t('connections.move-connection-disclaimer') }}</p>
          </simple-confirmation-dialog>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'

import { STATUS_PROCESSING, STATUS_FAILED, STATUS_SUCCEEDED, G_PRODUCT_TYPE_MVE } from '@/Globals'

import SimpleConfirmationDialog from '@/components/ui-components/SimpleConfirmationDialog.vue'
import ConnectionDetails from '@/components/move-connections/ConnectionDetails.vue'

export default {
  name: 'MoveSummary',

  components: {
    'simple-confirmation-dialog': SimpleConfirmationDialog,
    'connection-details': ConnectionDetails,
  },

  props: {
    currentService: {
      type: Object,
      required: true,
    },
    destinationService: {
      type: Object,
      required: true,
    },
    connectionsToMove: {
      type: Array,
      required: true,
    },
    connectionStatusMap: {
      type: Object,
      required: true,
    },
    vnicDetails: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      showConfirmationDialog: false,
      // Constants
      STATUS_PROCESSING,
      STATUS_FAILED,
      STATUS_SUCCEEDED,
    }
  },

  computed: {
    ...mapGetters('Services', ['connectionUidDictionary']),

    showDiversityWarning() {
      return this.currentService.diversityZone != null
        && this.destinationService.diversityZone != null
        && this.currentService.diversityZone !== this.destinationService.diversityZone
    },
    submitted() {
      return Object.keys(this.connectionStatusMap).length > 0
    },
    movedConnections() {
      return this.connectionsToMove.map(connectionUid => ({
        ...this.connectionUidDictionary[connectionUid],
        ...this.connectionStatusMap[connectionUid],
      }))
    },
    isMovingMultipleVNics() {
      return this.destinationService.productType === G_PRODUCT_TYPE_MVE
        && this.destinationService?.vnics.length > 1
    },
  },

  methods: {
    submitMove() {
      this.showConfirmationDialog = false
      this.$emit('submit')
    },
    selectedVNic(productUid) {
      const vNicConnection = this.vnicDetails.find(vNic => vNic.connectionUid === productUid)

      // Set the inner VLAN to untagged or auto assigned depending on the value
      let innerVlan = vNicConnection.innerVlan

      if (vNicConnection.innerVlan === -1) {
        innerVlan = this.$t('connections.untagged')
      } else if (vNicConnection.innerVlan === 0) {
        innerVlan = this.$t('connections.auto-assigned')
      }

      return {
        vNicDescription: this.destinationService.vnics[vNicConnection.vNicIndex].description,
        innerVlan,
      }
    },
    end(connection) {
      if (connection.productType !== this.G_PRODUCT_TYPE_IX) {
        return this.currentService.productUid === connection.aEnd.productUid ? 'A' : 'B'
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-table {
  margin-top: 3rem;
}
.summary-table::v-deep .summary-table-cell > div.cell {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-inline: 1rem;
  padding-inline: 0;
  word-break: normal;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.-mb-1rem {
  margin-bottom: -1rem;
}

.simple-confirmation-dialog-body > p {
  margin-top: 0;
}
::v-deep .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
