<template>
  <el-row type="flex"
    justify="center"
    align="middle"
    class="w-1000px">
    <el-col class="w-760px">
      <div class="bs-border-box">
        <el-card shadow="never">
          <!-- Sub-title: vNIC Details -->
          <h3 class="mt-1 mb-2 text-align-center font-weight-500">
            {{ $t('connections.new-vnic-details') }}
          </h3>

          <el-form ref="vNicDetailsForm"
            :model="vNicDetailsForm"
            label-width="200px"
            @submit.native.prevent>
            <div v-for="(connection, index) in connectionsWithEnd"
              :key="connection.connectionUid"
              class="connection-box">
              <h4>{{ allConnections[index].productName }}</h4>

              <!-- x-End vNIC -->
              <el-form-item :prop="`vNicIndex-${index}`">
                <template #label>
                  {{ $t('connections.new-end-vnic', { end: connection.end.toLocaleUpperCase() }) }}
                  <el-tooltip placement="top"
                    :content="$t('tooltips.move-connection-vnic')"
                    :open-delay="500">
                    <i class="fas fa-question-circle color-info popover-info-icon"
                      aria-hidden="true" />
                  </el-tooltip>
                </template>
                <el-select :value="vNicDetailsForm.connections[index].vNicIndex"
                  class="full-width"
                  :data-testid="`vnic-select-${index}`"
                  @change="handleVNicChange($event, index)">
                  <el-option v-for="(vNic, idx) in destinationService.vnics"
                    :key="`vNIC-${idx}`"
                    :label="`vNIC-${idx} ${vNic.description || ''}`"
                    :value="idx" />
                </el-select>
              </el-form-item>

              <!-- Field: Preferred x-End Inner VLAN -->
              <input-vlan :ref="`innerVlan-${index}`"
                v-model="vNicDetailsForm.connections[index].innerVlan"
                :second-value="destinationService.vnics[vNicDetailsForm.connections[index].vNicIndex].vlan"
                :prop="`innerVlan-${index}`"
                :service="serviceFormCombination[index]"
                :destination-service-uid="destinationService.productUid"
                :disabled="isIbmConnection(index)"
                :end="connection.end" />
            </div>
          </el-form>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty, merge } from 'lodash'

import { deepClone } from '@/helpers.js'
import InputVlanComponent from '@/components/InputVlan.vue'
import captureSentryError from '@/utils/CaptureSentryError'

export default {
  name: 'MoveVNicDetails',

  components: {
    'input-vlan': InputVlanComponent,
  },

  props: {
    currentService: {
      type: Object,
      required: true,
    },
    destinationService: {
      type: Object,
      required: true,
    },
    connectionsToMove: {
      type: Array,
      required: true,
    },
    vnicDetails: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      vNicDetailsForm: {
        connections: this.vnicDetails,
      },
    }
  },

  computed: {
    ...mapGetters('Services', ['connectionUidDictionary']),
    allConnections() {
      const connections = []

      for (const connection of this.connectionsToMove) {
        connections.push(this.connectionUidDictionary[connection])
      }

      return connections
    },

    /**
     * Combine the current form data with the rest of the non-editable connection data
     */
    serviceFormCombination() {
      const services = []

      for (let i = 0; i < this.connectionsToMove.length; i++) {
        const connection = deepClone(this.allConnections[i])
        services.push(merge(connection, { aEnd: this.vNicDetailsForm.connections[i] }))
      }

      return services
    },

    /**
     * Check what end the connections being moved are on and add to the array of connection Uids
     */
    connectionsWithEnd() {
      const connectionEnds = []

      for (const connectionUid of this.connectionsToMove) {
        const connectionDetails = this.connectionUidDictionary[connectionUid]

        if (connectionDetails.productType === this.G_PRODUCT_TYPE_IX) {
          connectionEnds.push({ connectionUid, end: 'a' })
        } else {
          const end = this.currentService.productUid === connectionDetails.aEnd.productUid ? 'a' : 'b'
          connectionEnds.push({ connectionUid, end })
        }
      }

      return connectionEnds
    },
  },

  watch: {
    vNicDetailsForm: {
      deep: true,
      handler() {
        this.validateVlanFields()
      },
    },
  },

  created() {
    this.initForm()
  },

  methods: {
    /**
     * Initialise form using the first vNIC while attempting to keep the preferred vlan the same
     */
    initForm() {
      // Use existing vNIC details if they exist
      if (this.vnicDetails.length) {
        this.vNicDetailsForm.connections = this.vnicDetails
        return
      }

      for (const connectionUid of this.connectionsToMove) {
        const end = this.connectionsWithEnd.find(({ connectionUid: uid }) => uid === connectionUid).end === 'a'
          ? 'aEnd'
          : 'bEnd'
        const innerVlan = this.connectionUidDictionary[connectionUid][end].innerVlan

        this.vNicDetailsForm.connections.push({
          connectionUid,
          vNicIndex: 0,
          innerVlan,
        })
      }
      this.validateVlanFields()
    },

    /**
     * Validate all vlan fields on the page at the same time
     */
    validateVlanFields() {
      let isValid = false
      let isValidArr = []

      this.$nextTick(() => {
        // Loop through all connections being moved and validate the VLAN field for each
        for (let i = 0; i < this.connectionsToMove.length; i++) {
          const vlanComponent = this.$refs[`innerVlan-${i}`][0]

          if (isEmpty(vlanComponent)) {
            captureSentryError(new Error('Field name must match ref'))
            isValidArr.push(false)
            return
          }

          vlanComponent.triggerValidation()

          if (vlanComponent.vlanValid) {
            isValidArr.push(true)
          } else if (vlanComponent.checkingVlan) {
            vlanComponent.$on('vlanCheckComplete', () => {
              isValidArr.push(vlanComponent.vlanValid)
              vlanComponent.$off('vlanCheckComplete')
            })
          } else {
            isValidArr.push(false)
          }
        }

        // If any one of those VLAN fields is invalid, the form is invalid
        isValid = !isValidArr.includes(false)

        // Check for any connections that have the same vNIC and VLAN combination and mark the form as invalid if so
        if (this.vNicDetailsForm.connections.length > 1) {
          const countMap = this.vNicDetailsForm.connections.reduce((map, { vNicIndex, innerVlan }) => {
            const key = `${vNicIndex}-${innerVlan}`
            map[key] = (map[key] || 0) + 1
            return map
          }, {})

          const hasDuplicates = Object.values(countMap).some(count => count > 1)
          isValid = !hasDuplicates
        }

        this.$emit('submitted', this.vNicDetailsForm.connections, isValid)
      })
    },

    /**
     * Update vNicIndex and validate the VLAN fields when the vNIC is changed
     * @param {number} value The new vNIC index
     * @param {number} index The index of the connection being moved
     */
    handleVNicChange(value, index) {
      this.vNicDetailsForm.connections[index].vNicIndex = value
      this.validateVlanFields()
    },

    isIbmConnection(index) {
      return this.allConnections[index].resources?.csp_connection?.csp_name === 'IBM'
    },
  },
}
</script>

<style lang="scss" scoped>
.connection-box {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem 1.4rem 0 1rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);

  h4 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
}
</style>
