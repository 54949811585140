<template>
  <div class="move-connection-cell"
    data-testid="connection-cell"
    :data-connection-name="connection.productName"
    :data-diversity-zone="otherEndDiversity || 'none'">
    <!-- Connection Icon -->
    <div class="move-connection-icon">
      <mu-mega-icon :icon="iconName" />
    </div>
    <!-- Connection Details -->
    <div class="move-connection-details">
      <strong class="move-connection-line">{{ connection.productName }}</strong>
      <span class="move-connection-line">
        <strong v-if="end">{{ $t('general.x-end', { end }) }}</strong>
        {{ connectionDetails }}
      </span>
      <span v-if="otherEndDiversity"
        class="diversity-zone">
        {{ $t('services.diversity-zone') }}
        <i class="far fa-dot-circle"
          :class="`diversity-color-${otherEndDiversity}`"
          aria-hidden="true" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { convertSpeed } from '@/helpers.js'

export default {
  name: 'ConnectionDetails',

  props: {
    connection: {
      type: Object,
      required: true,
    },
    parentUid: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('Services', ['findPort']),
    ...mapGetters('Company', ['companyUid']),
    ...mapState('Marketplace', ['marketplaceData']),

    end() {
      if (this.connection.productType !== this.G_PRODUCT_TYPE_IX) {
        return this.parentUid === this.connection.aEnd.productUid ? 'A' : 'B'
      }
      return null
    },
    otherEnd() {
      if (this.end) {
        return this.end === 'A'
          ? this.findPort(this.connection.bEnd.productUid)
          : this.findPort(this.connection.aEnd.productUid)
      }
      return null
    },
    otherEndDiversity() {
      if (this.otherEnd) {
        return this.otherEnd.config?.diversityZone || this.otherEnd.diversityZone
      }
      return null
    },
    iconName() {
      if (this.connection.productType === this.G_PRODUCT_TYPE_VXC && this.otherEnd) {
        if (this.otherEnd.connectType === 'TRANSIT') {
          return 'MegaportInternet'
        } else if (this.otherEnd.companyUid !== this.companyUid
          && this.marketplaceData.some(mp => mp.companyUid === this.otherEnd.companyUid)) {
          return 'Marketplace'
        }
      }
      return this.connection.productType
    },
    connectionDetails() {
      const typeString = this.$t(`productNames.${this.connection.productType.toLowerCase()}`)
      const speedString = convertSpeed(this.connection.rateLimit)
      const otherEndString = this.otherEnd ? ` - ${this.otherEnd.title}` : ''
      return `${typeString} (${speedString})${otherEndString}`
    },
  },
}
</script>
