<template>
  <el-form label-position="top">
    <el-row type="flex"
      justify="center"
      align="middle"
      class="w-1000px">
      <!-- Select Connection Card -->
      <el-col v-loading="!servicesReady"
        :span="11">
        <el-card shadow="never"
          class="content-card bs-border-box">
          <!-- Header -->
          <h3 class="text-align-center">
            {{ $t('general.connections') }}
          </h3>
          <!-- Selection Box -->
          <el-form-item :label="$t('connections.select-connections')"
            required>
            <!-- Select All Button -->
            <div class="flex-row-centered select-all">
              <el-button size="small"
                @click="selectAllValidConnections">
                {{ $t(allValidConnectionsSelected ? 'general.deselect-all' : 'general.select-all') }}
              </el-button>
            </div>
            <!-- Selection Box -->
            <div class="item-select-holder">
              <el-table :data="currentServiceConnections"
                :show-header="false"
                :empty-text="$t('connections.no-valid-connections')"
                row-class-name="item-row"
                class="item-select-table"
                @row-click="handleConnectionSelection">
                <el-table-column min-width="100">
                  <template #default="scope">
                    <!-- Disabled Option -->
                    <el-tooltip v-if="scope.row.disableReason != null"
                      placement="left"
                      :content="scope.row.disableReason">
                      <div class="selectable-item-cell disabled-row">
                        <connection-details :connection="scope.row"
                          :parent-uid="currentService.productUid" />
                      </div>
                    </el-tooltip>
                    <!-- Enabled Option -->
                    <div v-else
                      class="selectable-item-cell"
                      :class="{ 'current-row': connectionSelected(scope.row) }">
                      <connection-details :connection="scope.row"
                        :parent-uid="currentService.productUid" />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-card>
      </el-col>
      <!-- Arrow Container -->
      <el-col :span="2"
        class="text-align-center">
        <!-- Arrow Icon -->
        <i class="connection-arrow-icon fal fa-arrow-alt-right color-text-primary"
          aria-hidden="true" />
      </el-col>
      <!-- Select Destination Card -->
      <el-col v-loading="!servicesReady"
        :span="11">
        <el-card shadow="never"
          class="content-card bs-border-box">
          <!-- Header -->
          <h3 class="text-align-center">
            {{ $t('general.move-to') }}
          </h3>
          <el-form-item required>
            <!-- Selection Box Label -->
            <template #label>
              <span>{{ $t('connections.select-destination') }}</span>
              <el-tooltip placement="top"
                :content="$t('tooltips.move-connection-limits')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <!-- Diversity Zone Filter -->
            <div class="flex-row-centered zone-table-header">
              <div>{{ $t('services.diversity-zone') }}:</div>
              <div>
                <el-radio-group v-model="zoneFilter"
                  size="mini">
                  <el-radio-button :label="null">
                    {{ $t('general.all') }}
                  </el-radio-button>
                  <el-radio-button :label="G_DIVERSITY_ZONE_RED"
                    class="dz-dot">
                    <i class="far fa-dot-circle diversity-icon diversity-color-red"
                      :title="$t(`services.red-zone`)" />
                  </el-radio-button>
                  <el-radio-button :label="G_DIVERSITY_ZONE_BLUE"
                    class="dz-dot">
                    <i class="far fa-dot-circle diversity-icon diversity-color-blue"
                      :title="$t(`services.blue-zone`)" />
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <!-- Selection Box -->
            <div class="item-select-holder">
              <el-table :data="filteredDestinations"
                :show-header="false"
                :empty-text="$t('connections.no-valid-destinations')"
                row-class-name="item-row"
                class="item-select-table"
                @row-click="handleDestinationSelection">
                <el-table-column min-width="100">
                  <template #default="scope">
                    <!-- Disabled Option -->
                    <el-tooltip v-if="scope.row.disableReason != null"
                      placement="right"
                      :content="scope.row.disableReason">
                      <div class="selectable-item-cell disabled-row">
                        <destination-details :destination="scope.row" />
                      </div>
                    </el-tooltip>
                    <!-- Enabled Option -->
                    <div v-else
                      class="selectable-item-cell"
                      :class="{ 'current-row': destinationSelected(scope.row) }">
                      <destination-details :destination="scope.row" />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-card>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { connectionNoMoveReason } from '@/utils/moveConnections'

import ConnectionDetails from '@/components/move-connections/ConnectionDetails.vue'
import DestinationDetails from '@/components/move-connections/DestinationDetails.vue'

export default {
  name: 'MoveTargetSelect',

  components: {
    'connection-details': ConnectionDetails,
    'destination-details': DestinationDetails,
  },

  props: {
    currentService: {
      type: Object,
      required: true,
    },
    connectionsToMove: {
      type: Array,
      required: false,
      default: () => [],
    },
    destinationServiceUid: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      zoneFilter: null,
    }
  },

  computed: {
    ...mapGetters('Services', ['getValidMoveTargets']),
    ...mapState('Services', ['servicesReady']),

    currentServiceConnections() {
      const associatedVxcs = this.currentService.associatedVxcs || []
      const associatedIxs = this.currentService.associatedIxs || []
      return [...associatedVxcs, ...associatedIxs].map(connection => {
        const reasonKey = connectionNoMoveReason(connection)
        const typeKey = connection.productType === this.G_PRODUCT_TYPE_IX ? 'productNames.ix' : 'productNames.vxc'
        return {
          ...connection,
          disableReason: reasonKey && this.$t(reasonKey, { type: this.$t(typeKey) }),
        }
      })
    },
    validConnections() {
      return this.currentServiceConnections
        .filter(({ disableReason }) => disableReason == null)
        .map(({ productUid }) => productUid)
    },
    allValidConnectionsSelected() {
      return this.validConnections.every(connection => this.connectionsToMove.includes(connection))
    },
    selectedConnections() {
      return this.currentServiceConnections.filter(({ productUid }) => this.connectionsToMove.includes(productUid))
    },
    selectedConnectionsOtherEnds() {
      return this.selectedConnections.reduce((otherEnds, connection) => {
        if (connection.productType !== this.G_PRODUCT_TYPE_IX) {
          const other = this.currentService.productUid === connection.aEnd.productUid ? 'b' : 'a'
          return [...otherEnds, connection[`${other}End`].productUid]
        }
        return otherEnds
      }, [])
    },
    selectedConnectionsMaxSpeed() {
      return Math.max(...this.selectedConnections.map(({ rateLimit }) => rateLimit))
    },
    selectedConnectionsVlans() {
      return this.getConnectionVlans(this.selectedConnections, this.currentService.productUid)
    },
    filteredDestinations() {
      return this.getValidMoveTargets(this.currentService)
        .filter(port => this.zoneFilter == null || port.diversityZone === this.zoneFilter)
        .map(port => {
          let reasonKey = null
          // Check for a port that is already attached to a selected connection
          if (this.selectedConnectionsOtherEnds.includes(port.productUid)) {
            reasonKey = 'connections.invalid-move-existing-end'
          // Check for a port that is smaller than the largest selected connection
          } else if (port._aggSpeed != null && port._aggSpeed < this.selectedConnectionsMaxSpeed) {
            reasonKey = 'connections.invalid-move-too-small'
          // VLAN checks
          } else {
            const portConnections = [...port.associatedVxcs, ...port.associatedIxs]
            // Check for an untagged connection going to a port with connections
            // Skip for inner vlans as untagged inner vlans can exist alongside tagged inner vlans
            if (this.vlanType !== 'innerVlan' && this.hasUntaggedVlan(this.selectedConnectionsVlans) && portConnections.length > 0) {
              reasonKey = 'connections.invalid-move-new-untagged'
            } else {
              const portVlans = this.getConnectionVlans(portConnections, port.productUid)
              // Check for an existing untagged connection on the target port
              if (this.vlanType !== 'innerVlan' && this.hasUntaggedVlan(portVlans)) {
                reasonKey = 'connections.invalid-move-untagged'
              // Check for a vlan that is already taken on the port but exclude destination ports with
              // multiple vNICs as it can be changed on the next step
              } else if (port?.vnics?.length <= 1 && portVlans.some(vlan => this.selectedConnectionsVlans.includes(vlan))) {
                reasonKey = 'connections.invalid-move-vlan-taken'
              }
            }
          }
          return {
            ...port,
            disableReason: reasonKey && this.$t(reasonKey, { type: this.$t(this.productTypeKey) }),
          }
        })
        .sort((a, b) => b.createDate - a.createDate)
    },
    productTypeKey() {
      switch (this.currentService.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          return 'productNames.mcr'
        case this.G_PRODUCT_TYPE_MVE:
          return 'productNames.mve'
        default:
          return 'productNames.port'
      }
    },
    vlanType() {
      return this.currentService.productType === this.G_PRODUCT_TYPE_MVE ? 'innerVlan' : 'vlan'
    },
  },

  watch: {
    connectionsToMove(connections) {
      // Make sure no invalid connections are selected, which can happen by messing with the url
      if (connections.some(connection => !this.validConnections.includes(connection))) {
        this.$emit('update:connectionsToMove', connections.filter(connection => this.validConnections.includes(connection)))
      }
    },
    filteredDestinations(destinations) {
      // Ensure we deselect the new end if it becomes invalid
      if (this.destinationServiceUid && destinations.every(port => port.productUid !== this.destinationServiceUid || port.disableReason != null)) {
        this.$emit('update:destinationServiceUid', null)
      }
    },
  },

  methods: {
    ////////////////
    // #region Connection selection methods
    connectionSelected({ productUid }) {
      return this.connectionsToMove.includes(productUid)
    },
    handleConnectionSelection(connection) {
      if (connection.disableReason != null) return
      // Clone the list so we don't mutate our prop
      const updatedList = [...this.connectionsToMove]
      const existingIndex = updatedList.findIndex(connectionUid => connectionUid === connection.productUid)
      if (existingIndex < 0) {
        updatedList.push(connection.productUid)
      } else {
        updatedList.splice(existingIndex, 1)
      }
      this.$emit('update:connectionsToMove', updatedList)
    },
    selectAllValidConnections() {
      // If all valid connections are already selected, deselect them instead
      // Otherwise clone the valid connection list so we don't copy by reference and indirectly edit the computed property 😵‍💫
      this.$emit('update:connectionsToMove', this.allValidConnectionsSelected ? [] : [...this.validConnections])
    },
    // #endregion //
    ////////////////

    ////////////////
    // #region Destination selection methods
    destinationSelected({ productUid }) {
      return this.destinationServiceUid === productUid
    },
    handleDestinationSelection(destination) {
      if (destination.disableReason != null) return
      this.$emit('update:destinationServiceUid', this.destinationServiceUid === destination.productUid ? null : destination.productUid)
    },
    // #endregion //
    ////////////////

    getConnectionVlans(connections, currentServiceUid) {
      return connections.map(connection => {
        if (connection.productType === this.G_PRODUCT_TYPE_IX) return connection[this.vlanType]
        const current = connection.bEnd.productUid === currentServiceUid ? 'b' : 'a'
        return connection[`${current}End`][this.vlanType]
      })
    },
    hasUntaggedVlan(vlans) {
      return vlans.includes(null) || vlans.includes(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.content-card {
  height: 550px;
}
.connection-arrow-icon {
  font-size: 2rem;
  text-decoration: none;
}
.select-all {
  justify-content: flex-end;
  padding: 4px 0;
}
.zone-table-header {
  justify-content: flex-end;
  color: var(--color-text-regular);
  font-weight: 400;
  &::v-deep > div {
    margin-left: 5px;
  }
}

.item-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  height: 380px;
  overflow-y: auto;
}
.item-select-table {
  width: 100%;
  border-collapse: collapse;

  &::before {
    height: 0;
  }
  &::v-deep {
    table tr.item-row {
      background-color: #fbfbfb;
      &:hover > td {
        background-color: #fbfbfb;
      }
    }
    td {
      padding: 0;
      border: 0;
    }
  }
}
.selectable-item-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;

  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  &.disabled-row {
    border-color: var(--color-info-light-5);
    background-color: var(--color-info-light-8);
    cursor: auto;
  }
  &.current-row {
    border-color: var(--color-primary);
    background-color: var(--color-primary-light-8);
  }
}
</style>
