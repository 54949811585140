<template>
  <section>
    <!-- Header with title, steps, next and previous buttons -->
    <header class="process-steps-header"
      :class="{ 'sticky-top': !layout.integratedHeader }">
      <h3 class="text-align-center">
        {{ $t('page-titles.move-connections') }}
      </h3>

      <process-steps :steps="steps"
        :selected-step.sync="selectedStep" />
    </header>

    <!-- Main Content -->
    <section class="flex-column flex-align-center p-1-5">
      <!-- From / To Details -->
      <div class="flex-row-centered">
        <!-- From -->
        <div class="flex-column">
          <h3 class="text-align-center">
            {{ $t('general.from') }}
          </h3>
          <end-details-card :left-icon-id="currentServiceType"
            :detail-lines="currentServiceDetails"
            :diversity-zone="currentService.diversityZone" />
        </div>
        <template v-if="selectedStep > 0">
          <!-- Arrow Icon -->
          <i class="connection-arrow-icon fal fa-arrow-alt-right color-text-primary mt-3"
            aria-hidden="true" />
          <!-- To -->
          <div class="flex-column">
            <h3 class="text-align-center">
              {{ $t('general.to') }}
            </h3>
            <end-details-card :left-icon-id="destinationServiceType"
              :detail-lines="destinationServiceDetails"
              :diversity-zone="destinationService.diversityZone" />
          </div>
        </template>
      </div>

      <!-- Main Content Cards -->
      <!-- STEP: Select New End -->
      <move-target-select v-if="currentStep.id === 'select-end'"
        :current-service="currentService"
        :connections-to-move.sync="connectionsToMove"
        :destination-service-uid.sync="destinationServiceUid" />

      <!-- STEP: vNIC Details -->
      <move-vnic-details v-else-if="currentStep.id === 'vnic-details'"
        :current-service="currentService"
        :destination-service="destinationService"
        :connections-to-move="connectionsToMove"
        :vnic-details="connectionVNics"
        @submitted="validateConfigStep" />

      <!-- STEP: Summary -->
      <move-summary v-else-if="currentStep.id === 'summary'"
        :current-service="currentService"
        :destination-service="destinationService"
        :connections-to-move="connectionsToMove"
        :connection-status-map="connectionStatusMap"
        :vnic-details="connectionVNics"
        @submit="moveConnections" />

      <!-- Action Buttons -->
      <div class="flex-row-centered justify-content-end mt-2"
        :class="selectedStep === 0 ? 'w-1000px' : 'w-760px'">
        <!-- Cancel/Close Button -->
        <el-button :type="submitted ? 'primary' : undefined"
          @click="$router.push('/services')">
          {{ $t(submitted ? 'general.close' : 'general.cancel') }}
        </el-button>
        <!-- Back Button -->
        <el-button v-if="selectedStep > 0 && !submitted"
          type="primary"
          data-testid="back-button"
          @click="selectedStep--">
          <i class="fas fa-arrow-circle-left"
            aria-hidden="true" />
          {{ $t('general.back') }}
        </el-button>
        <!-- Next Button -->
        <el-button v-if="!isLastStep"
          type="primary"
          data-testid="next-button"
          :plain="!stepComplete"
          :disabled="!stepComplete"
          @click="selectedStep++">
          {{ $t('general.next') }}
          <i class="fas fa-arrow-circle-right"
            aria-hidden="true" />
        </el-button>
      </div>
    </section>
  </section>
</template>

<script>
// External tools
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
// Internal tools
import {
  PROCESS_STEP_UNAVAILABLE,
  PROCESS_STEP_AVAILABLE,
  PROCESS_STEP_COMPLETE,
  STATUS_PROCESSING,
  STATUS_FAILED,
  STATUS_SUCCEEDED,
  G_PRODUCT_TYPE_MVE,
} from '@/Globals'
// Components
import EndDetailsCard from '@/components/ui-components/EndDetailsCard.vue'
import ProcessSteps from '@/components/ui-components/ProcessSteps.vue'
import MoveTargetSelect from '@/components/move-connections/MoveTargetSelect.vue'
import MoveVNicDetails from '@/components/move-connections/MoveVnicDetails.vue'
import MoveSummary from '@/components/move-connections/MoveSummary.vue'

export default {
  name: 'MoveConnections',

  components: {
    'end-details-card': EndDetailsCard,
    'process-steps': ProcessSteps,
    'move-target-select': MoveTargetSelect,
    'move-vnic-details': MoveVNicDetails,
    'move-summary': MoveSummary,
  },

  inject: ['layout'],

  data() {
    return {
      selectedStep: 0,
      destinationServiceUid: null,
      connectionsToMove: [],
      connectionStatusMap: {},
      validVNicDetailsStep: false,
      connectionVNics: [],
    }
  },

  computed: {
    ...mapGetters('Services', ['portUidDictionary', 'connectionUidDictionary']),

    ////////////////
    // #region Step definitions
    steps() {
      let selectEndStatus
      if (this.submitted) {
        selectEndStatus = PROCESS_STEP_UNAVAILABLE
      } else if (this.servicesSelected) {
        selectEndStatus = PROCESS_STEP_COMPLETE
      } else {
        selectEndStatus = PROCESS_STEP_AVAILABLE
      }

      let vNicDetailsStatus
      if (this.submitted) {
        vNicDetailsStatus = PROCESS_STEP_UNAVAILABLE
      } else if (this.validVNicDetailsStep) {
        vNicDetailsStatus = PROCESS_STEP_COMPLETE
      } else {
        vNicDetailsStatus = PROCESS_STEP_AVAILABLE
      }

      let summaryStatus
      if ((!this.destinationServiceHasMultipleVNics && this.servicesSelected) ||
        (this.destinationServiceHasMultipleVNics && this.validVNicDetailsStep && this.servicesSelected)) {
        summaryStatus = PROCESS_STEP_AVAILABLE
      } else {
        summaryStatus = PROCESS_STEP_UNAVAILABLE
      }

      const steps = []

      steps.push({
        id: 'select-end',
        title: this.$t('connections.select-new-end'),
        status: selectEndStatus,
      })

      // Show the vNIC details step when moving to an MVE service with multiple vnics
      if (this.destinationServiceHasMultipleVNics) {
        steps.push({
          id: 'vnic-details',
          title: this.$t('connections.vnic-details'),
          status: vNicDetailsStatus,
        })
      }

      steps.push({
        id: 'summary',
        title: this.$t('general.summary'),
        status: summaryStatus,
      })

      return steps
    },
    currentStep() {
      return this.steps[this.selectedStep]
    },
    isLastStep() {
      return this.selectedStep === this.steps.length - 1
    },
    stepComplete() {
      return this.steps[this.selectedStep].status === PROCESS_STEP_COMPLETE
    },
    // #endregion //
    ////////////////

    ////////////////
    // #region Fields for the service being moved away from
    currentServiceUid() {
      return this.$route.params.productUid
    },
    currentService() {
      return this.portUidDictionary[this.currentServiceUid] ?? {}
    },
    currentServiceType() {
      return this.currentService.productType
    },
    currentServiceDetails() {
      const detailLines = []
      detailLines.push(this.currentService.productName || this.$t('general.untitled'))
      detailLines.push(this.currentServiceType === this.G_PRODUCT_TYPE_MVE
        ? `${this.$t('ports.vendor')}: ${this.currentService.vendor}, ${this.$t('general.size')}: ${this.currentService.mveLabel}`
        : this.currentService._speed)
      detailLines.push(this.currentService._location?.formatted?.short ?? this.$t('general.unknown-location'))
      return detailLines
    },
    // #endregion //
    ////////////////

    ////////////////
    // #region Fields for the service being moved to
    destinationService() {
      return this.portUidDictionary[this.destinationServiceUid] ?? {}
    },
    destinationServiceType() {
      return this.destinationService.productType
    },
    destinationServiceDetails() {
      const detailLines = []
      detailLines.push(this.destinationService.productName || this.$t('general.untitled'))
      detailLines.push(this.destinationServiceType === this.G_PRODUCT_TYPE_MVE
        ? `${this.$t('ports.vendor')}: ${this.destinationService.vendor}, ${this.$t('general.size')}: ${this.destinationService.mveLabel}`
        : this.destinationService._speed)
      detailLines.push(this.destinationService._location?.formatted?.short ?? this.$t('general.unknown-location'))
      return detailLines
    },
    /** Determines if the destination service has multiple vNICs */
    destinationServiceHasMultipleVNics() {
      return this.destinationServiceType === G_PRODUCT_TYPE_MVE
        && this.destinationService?.vnics.length > 1
    },
    // #endregion //
    ////////////////

    servicesSelected() {
      return this.connectionsToMove.length > 0 && this.destinationServiceUid != null
    },
    submitted() {
      return Object.keys(this.connectionStatusMap).length > 0
    },
  },

  mounted() {
    const startingConnection = this.$route.query.connection
    if (startingConnection) {
      this.connectionsToMove = [startingConnection]
    }
  },

  methods: {
    ...mapActions('Services', ['moveConnection']),

    /**
     * Validates the vNIC details step and stores said vNIC details
     * @param {Array} data The vNIC details
     * @param {boolean} valid Whether the data sent back is valid
     */
    validateConfigStep(data, valid) {
      this.validVNicDetailsStep = valid
      this.connectionVNics = data
    },

    moveConnections() {
      this.connectionsToMove.forEach(connectionUid => {
        // Lookup the affected end for the connection
        const connection = this.connectionUidDictionary[connectionUid]
        let affectedEnd
        if (connection.productType === this.G_PRODUCT_TYPE_IX) {
          affectedEnd = 'a'
        } else {
          affectedEnd = this.currentServiceUid === connection.aEnd.productUid ? 'a' : 'b'
        }

        // Flag the connection as processing
        Vue.set(this.connectionStatusMap, connectionUid, { status: STATUS_PROCESSING })

        const payload = {
          connectionUid,
          affectedEnd: affectedEnd,
          oldEndUid: this.currentServiceUid,
          newEndUid: this.destinationServiceUid,
        }

        // If the destination service has multiple vNICs, include the vNIC index and vlans
        if (this.destinationServiceHasMultipleVNics && this.connectionVNics.length) {
          const connectionConfig = this.connectionVNics.find(c => c.connectionUid === connectionUid)
          if (connectionConfig) {
            payload.vNicIndex = connectionConfig.vNicIndex
            payload.vlan = this.destinationService.vnics[connectionConfig.vNicIndex].vlan
            payload.innerVlan = connectionConfig.innerVlan
          }
        }

        // Send the move request (intentionally not using await syntax so we can fire off multiple promises simultaneously)
        this.moveConnection(payload)
          .then(() => {
            this.connectionStatusMap[connectionUid].status = STATUS_SUCCEEDED
          }).catch(error => {
            this.connectionStatusMap[connectionUid] = {
              status: STATUS_FAILED,
              error: error?.message || this.$t('general.unknown-error'),
            }
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.diversity-zone {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.connection-arrow-icon {
  font-size: 2rem;
  text-decoration: none;
}
</style>

<style lang="scss">
.move-connection-cell {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 1rem;
  align-items: center;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  > .move-connection-icon {
    width: 4rem;
    height: 4rem;
    margin-left: 0.5rem;

    > svg {
      display: inline-block;
      color: var(--color-text-regular);
      width: 4rem;
      height: 4rem;
      fill: currentColor;
    }
  }

  > .move-connection-details {
    display: block;
    min-height: 52px;

    > .move-connection-line {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 310px;
      line-height: normal;
    }
  }
}
</style>
